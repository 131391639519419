import React from 'react'
import { StatusBar } from 'expo-status-bar'
import { UserProvider } from './global/UserProvider'
import TabNavigator from './global/NavigationMobile/TabNavigator'
import 'expo-firestore-offline-persistence'


export default function App() {
  return (
    <UserProvider>
      <TabNavigator />
      <StatusBar style='auto' />
    </UserProvider>
  )
}