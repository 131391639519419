import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import CatalogScreen from '../../screens/Catalog/CatalogScreen'

const CatalogStackNavigator = createStackNavigator()

export default function CartStack() {

  return (
    <CatalogStackNavigator.Navigator screenOptions={{headerTintColor: '#000000'}}>
      <CatalogStackNavigator.Screen name='Catalog' component={CatalogScreen} options={{ title: 'Каталог' }} />
    </CatalogStackNavigator.Navigator>
  )
}
