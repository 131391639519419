import React, { useEffect, useContext, useState }  from 'react'
import { View, ScrollView, StyleSheet, ActivityIndicator } from 'react-native'
import ProductGrid, { HORIZONTAL_PADDING } from '../../components/ProductGrid'
import Text from '../../components/Text'
import TextInput from '../../components/TextInput'
import Button from '../../components/Button'
import { UserContext } from '../../global/UserProvider'
import { db, getProductsByTag } from '../../global/Firebase/firebase'

export default function ProductTagScreen( { navigation, route } ) {

  const productTag = route.params?.productTag
  const [context, setContext] = useContext(UserContext)
  const [allProducts, setAllProducts] = useState(null)
  const [filteredProducts, setFilteredProducts] = useState(null)
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(1)
  const [query, setQuery] = useState('')
  const [descriptionText, setDescriptionText] = useState()

  useEffect(() => {
    // get all product tags if not loaded before to get names
    if (context.productTags.length == 0) {
      db.collection('productTags').orderBy('index').get().then(function(querySnapshot) {
        var docs = []
        querySnapshot.forEach(function(doc) {
          var snap = doc.data()
          snap.id = doc.id
          snap.ref = doc.ref
          docs.push(snap)
        })
        setContext(context => ({...context, productTags: docs}))
        for (const [index, category] of docs.entries()) {
          for (const [index, tag] of category.data.entries()) {
            if (tag.id === productTag) {
              navigation.setOptions({
                title: tag.name
              })
              setDescriptionText(tag.descriptionText)
            }
          }
        }
      })
    } else {
      for (const [index, category] of context.productTags.entries()) {
        for (const [index, tag] of category.data.entries()) {
          if (tag.id === productTag) {
            navigation.setOptions({
              title: tag.name
            })
            setDescriptionText(tag.descriptionText)
          }
        }
      }
    }
    
    getProducts()
  }, [productTag])

  const getProducts = async () => {
    const products = await getProductsByTag(productTag)
    setAllProducts(products)
    setFilteredProducts(products)
  }

  const handleSearch = text => {
    setQuery(text)
    const filteredData = allProducts.filter(function(product) {
      return product.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
    })
    setFilteredProducts(filteredData)
  }
  
  return (
    <ScrollView style={styles.container}>
      {/* <TextInput 
        style={[styles.marginVertical]}
        autoCapitalize="none"
        autoCorrect={false}
        clearButtonMode="always"
        value={query}
        onChangeText={queryText => handleSearch(queryText)}
        placeholder="Поиск" 
        returnKeyType='done'
        selectTextOnFocus={true}
      /> */}

      {/* <View style={styles.categoriesFilter}>
        <Button category={selectedCategoryIndex == -1 ? 'selectedTag' : 'tag'} style={styles.categoryTag} onPress={() => {
          setSelectedCategoryIndex(-1)
          setFilteredProducts(allProducts)
        }}>Все</Button>
        { context.productsWithCategories.map((category, index) => (
          <Button category={selectedCategoryIndex == index ? 'selectedTag' : 'tag'} key={category+index} style={styles.categoryTag} onPress={() => {
            const newData = [category]
            setFilteredProducts(newData)
            setSelectedCategoryIndex(index)
          }}>{category.title}</Button>
        ))}
      </View> */}

      { descriptionText && descriptionText.length > 0 && (
        <View style={styles.descriptionTextView}>
          <Text style={styles.descriptionText}>{descriptionText}</Text>
        </View>
      )}
      
      { filteredProducts
        ? 
          filteredProducts.length > 0
          ?
            
            <ProductGrid products={filteredProducts} store={context.store} />
          :
            <Text>Не найдено</Text>
        : 
          <ActivityIndicator style={styles.marginVertical} />
      }

      <View style={{marginBottom: 50}}></View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: HORIZONTAL_PADDING,
    paddingVertical: 20,
  },
  marginVertical: {
    marginVertical: 10,
  },
  categoriesFilter: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  categoryTag: {
    marginRight: 5,
    marginBottom: 5,
  },
  descriptionTextView: {
    borderRadius: 5, 
    backgroundColor: 'white',
    marginBottom: 20,
    padding: 10,
  },
  descriptionText: {
    
  }
})