import React, { useEffect, useContext, useState }  from 'react'
import { View, ScrollView, StyleSheet, ActivityIndicator } from 'react-native'
import ProductGrid, { HORIZONTAL_PADDING } from '../../components/ProductGrid'
import Text from '../../components/Text'
import TextInput from '../../components/TextInput'
import Button from '../../components/Button'
import { UserContext } from '../../global/UserProvider'

export default function CatalogScreen( { navigation, route } ) {
  const [context, setContext] = useContext(UserContext)
  const [filteredProducts, setFilteredProducts] = useState(context.productsWithCategories)
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(-1)
  const [query, setQuery] = useState('')

  useEffect(() => {
    setFilteredProducts(context.productsWithCategories)
  }, [context.productsWithCategories.length])

  const handleSearch = text => {
    setQuery(text)
    var filteredData = []
    for (const [index, value] of context.productsWithCategories.entries()) {
      const filteredValue = value.data.filter(function(product) {
        return product.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
      })
      // fuse - slow and categorized
      // const fuse = new Fuse(value.data, {
      //   keys: ['name']
      // })
      // const filteredValue = fuse.search(text).map(i => i.item)
      if (filteredValue.length > 0) {
        const newDict = {
          title: value.title,
          data: filteredValue
        }
        filteredData.push(newDict)
      }
    }
    if (filteredData.length == 0) {
      const newDict = {
        title: 'Не найдено',
        data: [],
      }
      filteredData.push(newDict)
    }
    if (text === '' && empty) {
      filteredData = []
    }
    setFilteredProducts(filteredData)
  }
  
  return (
    <ScrollView style={styles.container}>
      <TextInput 
        style={[styles.marginVertical]}
        autoCapitalize="none"
        autoCorrect={false}
        clearButtonMode="always"
        value={query}
        onChangeText={queryText => handleSearch(queryText)}
        placeholder="Поиск" 
        returnKeyType='done'
        selectTextOnFocus={true}
      />

      <View style={styles.categoriesFilter}>
        <Button category={selectedCategoryIndex == -1 ? 'selectedTag' : 'tag'} style={styles.categoryTag} onPress={() => {
          setSelectedCategoryIndex(-1)
          setFilteredProducts(context.productsWithCategories)
        }}>Все</Button>
        { context.productsWithCategories.map((category, index) => (
          <Button category={selectedCategoryIndex == index ? 'selectedTag' : 'tag'} key={category+index} style={styles.categoryTag} onPress={() => {
            const newData = [category]
            setFilteredProducts(newData)
            setSelectedCategoryIndex(index)
          }}>{category.title}</Button>
        ))}
      </View>
      
      { filteredProducts.length > 0
        ? 
          filteredProducts.map((category, index) => (
            <View key={category+index}>
              <Text category='h3' style={styles.marginVertical}>{category.title}</Text>
              <ProductGrid products={category.data} />
            </View>
          )) 
        : 
          <ActivityIndicator style={styles.marginVertical} />
      }

      <View style={{marginBottom: 50}}></View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: HORIZONTAL_PADDING,
    paddingVertical: 20,
  },
  marginVertical: {
    marginVertical: 10,
  },
  categoriesFilter: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  categoryTag: {
    marginRight: 5,
    marginBottom: 5,
  }
})