import React, { useEffect, useContext, useState, useRef }  from 'react'
import { View, ScrollView, StyleSheet, TouchableOpacity, TouchableHighlight, ActivityIndicator, Dimensions, Image, ImageBackground, SafeAreaView, Platform } from 'react-native'
import Text from '../../components/Text'
import TextInput from '../../components/TextInput'
import { getProducts, db, updateUserInfo } from '../../global/Firebase/firebase'
import { UserContext } from '../../global/UserProvider'
import colors from '../../utils.js/colors'
import Constants from 'expo-constants'
import { Ionicons } from '@expo/vector-icons'
import SearchScreen from './SearchScreen'
// import Modal from 'modal-react-native-web'
import Modal from '../../components/Modal'
import * as Analytics from 'expo-firebase-analytics'
import Swiper from 'react-native-web-swiper'

export const BORDER_RADIUS = 15

export default function HomeScreen( { navigation } ) {

  const [context, setContext] = useContext(UserContext)
  const [chooseStoreModalVisible, setChooseStoreModalVisible] = useState(false)
  const [query, setQuery] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    navigation.setOptions({
      header: () => {}
    })
    if (context.slides.length == 0) {
      var promises = []
      promises.push(db.collection('productTags').orderBy('index').get())
      promises.push(db.collection('constants').doc('slides').get())
      resolvePromises(promises)
    }
  }, [])

  const resolvePromises = async (promises) => {
    setIsLoading(true)
    const [ptQ, slidesQ] = await Promise.all(promises)
    let slides = slidesQ.data()?.data
    var docs = []
    ptQ.forEach(function(doc) {
      var snap = doc.data()
      snap.id = doc.id
      snap.ref = doc.ref
      docs.push(snap)
    })
    setContext(context => ({...context, productTags: docs, slides}))
    setIsLoading(false)
  }

  const calculateWidth = (size) => {
    if (size === 1) {
      return '30.3%'
    } else if (size === 2) {
      return '47%'
    } else {
      return '97%'
    }
  }

  return (
    <View style={{flex: 1, backgroundColor: (query.length == 0 ? undefined : 'white')}}>
      
      {query.length == 0 && (
         <View style={{paddingHorizontal: 20, paddingTop: Constants.statusBarHeight, height: 100, justifyContent: 'center', position: 'absolute'}}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Ionicons name="location-outline" size={20} color="black" style={{marginRight: 5}} />
            <Text category='h3' style={{marginRight: 5}}>{context.stores[context.store].name}</Text>
            {/* <Ionicons name="chevron-down" size={20} color="black" style={{marginRight: 5}} /> */}
          </View>
        </View>
      )}
     
      
      <ScrollView 
        keyboardDismissMode={Platform.OS !== 'web' ? 'on-drag' : 'interactive'}
        style={
          [
            styles.container, 
            { 
              shadowColor: (query.length == 0 ? '#000' : '#fff'),
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 3.84,
            }
          ]
        } 
        stickyHeaderIndices={Platform.OS !== 'android' ? [1] : undefined} 
      >
        {/* <TouchableHighlight  activeOpacity={0.5} underlayColor='rgba(255, 255, 255, 0.5)' onPress={() => {
          // setChooseStoreModalVisible(true)
          Analytics.logEvent('OpenChooseStoreModal', {
            screen: 'Home'
          })
        }}> */}
          <View style={{height: (query.length == 0 ? 140 : 0), width: Dimensions.get('window').width, paddingTop: Constants.statusBarHeight}}></View>
        {/* </TouchableHighlight> */}
          

        <View 
          style={[{
            paddingHorizontal: 14, paddingBottom: 15, backgroundColor: 'white', borderTopLeftRadius: 40, borderTopRightRadius: 40
          }, 
          Platform.OS === 'web' || Platform.OS === 'android' ? {
            marginTop: -40, paddingTop: 60
          } : {
            marginTop: -40, paddingTop: Constants.statusBarHeight
          }]}
        >
          <TextInput
            style={{borderRadius: BORDER_RADIUS}}
            placeholder='Поиск'
            returnKeyType='search'
            autoCapitalize="none"
            autoCorrect={false}
            clearButtonMode="always"
            clearAction={() => setQuery('')}
            value={query}
            onChangeText={setQuery}
          />
        </View>
        
        { query.length == 0 
          ?
            <View style={{flex: 1, paddingLeft: '3%', backgroundColor: 'white'}}>
              {
                isLoading 
                ?
                  <View style={{flex: 1, justifyContent: 'space-around' ,alignItems: 'center', paddingRight: '3%'}}><ActivityIndicator /></View>
                :
                  <View>
                    <View style={{height: 150, marginVertical: 20, width: calculateWidth(3)}}>
                      <Swiper
                        loop
                        timeout={3}
                        controlsProps={{
                          dotsTouchable: true,
                          prevPos: 'left',
                          nextPos: 'right',
                          nextTitle: '>',
                          prevTitle: '<',
                        }}
                      >
                        { context.slides?.map((value, index) => (
                          <View key={value+index} style={[styles.slideContainer, {backgroundColor: value.backgroundColor}]}>
                            <Text style={{fontSize: 20, margin: 10, color: 'white', fontWeight: '600', alignSelf: 'center', textAlign: 'center', marginTop: 20}}>{value.title}</Text>
                            <Image source={value.imageURL && { uri: 'https://storage.googleapis.com/qarasay-kz.appspot.com/productTagImages/' + encodeURIComponent(value.imageURL) }} style={{height: 60, resizeMode: 'contain'}} />
                          </View>
                        ))}
                      </Swiper>
                    </View>
                    { context.productTags.map((category, categoryIndex) => (
                      <View key={category+categoryIndex}>
                        <View style={{flexDirection: 'row', marginBottom: 7, marginHorizontal: 7, marginTop: 10, alignItems: 'center'}}>
                          <Text style={{ fontSize: 18, fontWeight: '700' }}>{category.name}</Text>
                        </View>
                        <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                          { category.data?.map((tile, tileIndex) => (
                            tile.activeForStore?.[context.store] && (
                              <TouchableOpacity key={tile+tileIndex} style={{height: 110, width: calculateWidth(tile.size), backgroundColor: tile.color, borderRadius: 15, marginBottom: '3%', marginRight: '3%', overflow: 'hidden'}} onPress={() => {
                                navigation.navigate('ProductTag', { productTag: tile.id })
                                Analytics.logEvent('ViewProductTag', {
                                  productTag: tile.name
                                })
                              }}>
                                <ImageBackground source={tile.imageURL && { uri: 'https://storage.googleapis.com/qarasay-kz.appspot.com/productTagImages/' + encodeURIComponent(tile.imageURL) }} style={{height: 110}} >
                                  <Text style={{fontSize: 16, margin: 10, color: 'white', fontWeight: '500'}}>{tile.name}</Text>
                                </ImageBackground>
                              </TouchableOpacity>
                            )
                          ))}
                        </View>
                      </View>
                    ))}
                  </View>
              }
            </View>
          :
            <SearchScreen style={{flex: 1}} query={query} />
        }

        <View style={{height: 50, backgroundColor: 'white'}}></View>
      </ScrollView>
      {/* <ChooseStoreModal chooseStoreModalVisible={chooseStoreModalVisible} setChooseStoreModalVisible={setChooseStoreModalVisible} /> */}
    </View>
  )
}

export const ChooseStoreModal = ({chooseStoreModalVisible, setChooseStoreModalVisible, }) => {

  const [context, setContext] = useContext(UserContext)

  return (
    <Modal
      // animationType="slide"
      transparent={true}
      visible={chooseStoreModalVisible}
    >
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
        <View style={{margin: 10,
          backgroundColor: 'white',
          borderRadius: 20,
          padding: 20,
          alignItems: 'center',
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
          // elevation: 5,
        }}
        >
          <Text category='h3' style={{textAlign: 'center', marginBottom: 10}}>Выберите магазин</Text>
          <TouchableOpacity style={{marginBottom: 10, borderRadius: 20, padding: 15, backgroundColor: context.store === 'samal' ? colors.lightPink : undefined, }} onPress={() => {
            context.user && updateUserInfo(context.user.uid, {store: 'samal'})
            setContext((context) => ({...context, store: 'samal'}))
            Analytics.logEvent('ChooseStore', {
              store: 'samal'
            })
            setChooseStoreModalVisible(false)
          }}>
            <Text style={{marginBottom: 3}} category='h3'>Магазин в Самале</Text>
            <Text style={{marginBottom: 3}}><Text style={{fontWeight: '600'}}>Адрес:</Text> Самал 2, дом 23</Text>
            <Text style={{marginBottom: 3}}><Text style={{fontWeight: '600'}}>Ассортимент:</Text> Молочные продукты, овощи, фрукты, ягоды, хлеб на закваске, курица, яйца, полуфабрикаты, мясо, стейки, мороженое</Text>
            <Text style={{marginBottom: 3}}><Text style={{fontWeight: '600'}}>Бесплатная доставка в квадрате:</Text> Жолдасбекова - Фурманова - Аль-Фараби - Достык</Text>
          </TouchableOpacity>
          {/* <TouchableOpacity style={{marginBottom: 10, borderRadius: 20, padding: 15, backgroundColor: context.store === 'zenkova' ? colors.lightPink : undefined}} onPress={() => {
            context.user && updateUserInfo(context.user.uid, {store: 'zenkova'})
            setContext((context) => ({...context, store: 'zenkova'}))
            Analytics.logEvent('ChooseStore', {
              store: 'zenkova'
            })
            setChooseStoreModalVisible(false)
          }}>
            <Text style={{marginBottom: 3}} category='h3'>Магазин на Зенкова</Text>
            <Text style={{marginBottom: 3}}><Text style={{fontWeight: '600'}}>Адрес:</Text> Зенкова 31</Text>
            <Text style={{marginBottom: 3}}><Text style={{fontWeight: '600'}}>Ассортимент:</Text> Молочные продукты, овощи, фрукты, ягоды, хлеб на закваске, курица, яйца, полуфабрикаты, <Text style={{textDecorationLine: 'line-through'}}>мясо, стейки,</Text> мороженое</Text>
            <Text style={{marginBottom: 3}}><Text style={{fontWeight: '600'}}>Бесплатная доставка в квадрате:</Text> Калдаякова - Толе Би - Пушкина - Шевченко</Text>
          </TouchableOpacity> */}

        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // height: Dimensions.get('screen').height - 100,
  },
  marginVertical: {
    marginVertical: 8,
  },
  marginHorizontal: {
    marginHorizontal: 8,
  },
  row: {
    flexDirection: 'row',
  },  
  categoryTitle: {
    marginTop: 15,
  },
  tile: {
    borderRadius: BORDER_RADIUS,
    marginHorizontal: 4,
    height: 100,
    overflow: 'hidden',
  }, 
  tileTitle: {
    color: 'white', 
    fontWeight: '600',
    marginHorizontal: 14, 
    marginTop: 11,
    fontSize: 15,
  },
  imageContainer: {
    height: 75, 
    alignSelf: 'flex-end'
  },
  slideContainer: {
    flex: 1,
    borderRadius: BORDER_RADIUS,
  },
})