import React, { useEffect, useContext, useState }  from 'react'
import { View, ScrollView, StyleSheet, ActivityIndicator, TouchableOpacity, Dimensions, Image, Platform } from 'react-native'
import { storage } from '../../global/Firebase/firebase'
import Text from '../../components/Text'
import Button from '../../components/Button'
import { Ionicons } from '@expo/vector-icons'
import colors from '../../utils.js/colors'
import { UserContext } from '../../global/UserProvider'
import TextInput from '../../components/TextInput'
import NextImage from '../../components/NextImage'

export default function SuccessScreen( { navigation, route } ) {
  const [context, setContext] = useContext(UserContext)

  useEffect(() => {
    if (context.cart.length > 0) {
      navigation.popToTop()
    }
  })
  
  return (
    <View style={{flex: 1,
      // height: Dimensions.get('screen').height-200,
      padding: 20,
      justifyContent: 'center',}}>
      {/* { Platform.OS === 'web' &&  
        <NextImage src='/smiling_dumplings_green.png' layout='fill' />
      }
      { Platform.OS !== 'web' && 
        <Image source={require('../../public/smiling_dumplings_green.png')} style={styles.emptyCartImage} />
      } */}
      <Image source={require('../../public/smiling_dumplings_green.png')} style={styles.emptyCartImage} />
      <Text category='s2' hint style={[styles.emptyCartText]}>Ваш заказ принят. В ближайшее рабочее время с вами свяжутся для уточнения деталей заказа 🙌</Text>
      <Text category='s2' hint style={[styles.emptyCartText]}>Часы работы магазина - с 8:30 до 21:00</Text>
      <Button 
        iconName='arrow-forward-circle-outline' 
        style={[styles.emptyCartButton, styles.marginVertical]}
        onPress={() => {
          navigation.popToTop()
          navigation.navigate('Home')
        }}
      >На главную</Button>
    </View>
  )
}

const styles = StyleSheet.create({
  emptyCartText: {
    // marginTop: (Dimensions.get('screen').height-300) / 2,
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  emptyCartImage: {
    alignSelf: 'center',
    height: 200,
    width: 200,
  },
})