import React, { useEffect, useContext, useState }  from 'react'
import { View, ScrollView, StyleSheet, ActivityIndicator, TouchableOpacity, Dimensions, Image, Platform } from 'react-native'
import { storage } from '../../global/Firebase/firebase'
import Text from '../../components/Text'
import Button from '../../components/Button'
import { Ionicons } from '@expo/vector-icons'
import colors from '../../utils.js/colors'
import { UserContext } from '../../global/UserProvider'
import TextInput from '../../components/TextInput'
import NextImage from '../../components/NextImage'
import AsyncStorage from '@react-native-async-storage/async-storage'
// import CachedImage from 'react-native-expo-cached-image'
import * as Analytics from 'expo-firebase-analytics'

export default function CartScreen( { navigation, route } ) {

  const [context, setContext] = useContext(UserContext)

  const deleteItemFromCart = (cartIndex) => {
    setContext(context => {
      var newCart = [...context.cart]
      newCart.splice(cartIndex, 1)
      AsyncStorage.setItem('cart', JSON.stringify(newCart))
      return {...context, cart: newCart}
    })
  }

  const updateQuantity = (index, stringQuantity) => {
    if (stringQuantity === '') {
      setContext(context => {
        var updatedCartItem = {...context.cart[index], quantity: 0}
        var newCart = [...context.cart]
        newCart.splice(index, 1, updatedCartItem)
        AsyncStorage.setItem('cart', JSON.stringify(newCart))
        return {...context, cart: newCart}
      })
    } else {
      setContext(context => {
        var updatedCartItem = {...context.cart[index]}
        if (context.cart[index].type) {
          updatedCartItem.quantity = parseFloat(stringQuantity)
        } else {
          updatedCartItem.quantity = parseInt(stringQuantity)
        }
        var newCart = [...context.cart]
        newCart.splice(index, 1, updatedCartItem)
        AsyncStorage.setItem('cart', JSON.stringify(newCart))
        return {...context, cart: newCart}
      })
    }
    
  }

  const proceedToCheckout = () => {
    // Analytics.logEvent('NavigateToCheckout')
    // navigation.navigate('Checkout')
    if (context.user || Platform.OS === 'web') {
      Analytics.logEvent('NavigateToCheckout')
      navigation.navigate('Checkout')
    } else {
      Analytics.logEvent('NavigateToLoginBeforeCheckout')
      navigation.navigate('ProfileStack')
    }
  }
  
  return (
    context.cart.length > 0
      ? 
        <View style={styles.container}>
          <ScrollView style={styles.cartContainer}>
            { context.cart.map((item, index) => (
              <CartItem key={item+index} product={item} index={index} deleteItemFromCart={deleteItemFromCart} updateQuantity={updateQuantity} store={context.store} />
            )) }
          </ScrollView>
          <TotalsView cart={context.cart} nextAction={proceedToCheckout} nextActionText={(context.user || Platform.OS === 'web') ? 'Далее' : 'Войдите чтобы продолжить'} store={context.store} bonusLevel={context.user?.bonusLevel || 3} />
        </View>
      : 
        <View style={styles.emptyCartView}>
          {/* { Platform.OS === 'web' &&  
            <NextImage src='/meditating_dumpling.png' layout='fill' />
          }
          { Platform.OS !== 'web' && 
            <Image source={require('../../public/meditating_dumpling.png')} style={styles.emptyCartImage} />
          } */}
          <Image source={require('../../public/meditating_dumpling.png')} style={styles.emptyCartImage} />
          <Text category='s2' hint style={[styles.emptyCartText]}>В корзине пока нет товаров</Text>
          <Button 
            category='primary' 
            iconName='arrow-forward-circle-outline' 
            style={[styles.emptyCartButton, styles.marginVertical]}
            onPress={() => {
              Analytics.logEvent('EmptyCartButton')
              navigation.navigate('HomeStack')
            }}
          >Выбрать товары в каталоге</Button>
        </View>
  )
}

const CartItem = ({ product, index, deleteItemFromCart, updateQuantity, store }) => {

  const [quantity, setQuantity] = useState(product.quantity.toString())
  const [productImageURI, setProductImageURI] = useState(null)

  useEffect(() => {
    setQuantity(product.quantity.toString())
  }, [product.quantity])

  const decreaseQuantity = () => {
    var newValue = ''
    if (product.type) {
      var val = parseFloat(quantity) - (product.defaultQuantity || 1) 
      if (val < (product.defaultQuantity || 1) ) {
        val = (product.defaultQuantity || 1) 
      }
      newValue = val.toFixed(1)
    } else {
      var val = parseInt(quantity) - (product.defaultQuantity || 1) 
      if (val < (product.defaultQuantity || 1)) {
        val = (product.defaultQuantity || 1)
      }
      newValue = val.toString()
    }
    setQuantity(newValue)
    updateQuantity(index, newValue)
  }

  const increaseQuantity = () => {
    var newValue = ''
    if (product.type) {
      const val = (parseFloat(quantity) + (product.defaultQuantity || 1))  
      newValue = val.toFixed(1)
    } else {
      const val = (parseInt(quantity) + (product.defaultQuantity || 1))  
      newValue = val.toString()
    }
    setQuantity(newValue)
    updateQuantity(index, newValue)
  }

  return (
    <View style={[styles.cartItemCard, styles.marginVertical]}>
      <View style={styles.imagePlaceholder}>
        { product.productImages && product.productImages[0] &&
          // <CachedImage source={{ uri: 'https://storage.googleapis.com/qarasay-kz.appspot.com/productImages/' + encodeURIComponent(product.productImages[0].fileName)  }} style={styles.image} />
          <Image source={{ uri: 'https://storage.googleapis.com/qarasay-kz.appspot.com/productImages/' + encodeURIComponent(product.productImages[0].fileName)  }} style={styles.image} />
        }
      </View>
      <View style={styles.cartItemRightPart}>
        <View style={styles.titleView}>
          <Text>{product.name}</Text>
          <TouchableOpacity onPress={() => {
              deleteItemFromCart(index)
              Analytics.logEvent('DeleteFromCart', {
                product: product.name,
              })
            }} 
            style={styles.deleteIcon}
          >
            <Ionicons name='trash-outline' size={15} color={colors.gray} />
          </TouchableOpacity>
        </View>
        <View style={styles.lowerView}>
          <Text hint={true} style={styles.priceIndividual}>{product.salePrices[store] + 'тг/' + (product.type ? 'кг' : 'шт')}</Text>
          <View style={styles.quantityView}>
            <TouchableOpacity onPress={decreaseQuantity}>
              <Ionicons name='remove-circle-outline' size={25} color={colors.borderGray} />
            </TouchableOpacity>
            <TextInput 
              value={quantity}
              onChangeText={(text) => {
                setQuantity(text.replace(/,/g, '.'))
                updateQuantity(index, text.replace(/,/g, '.'))
              }}
              style={styles.input} 
              returnKeyType='done'
              keyboardType='decimal-pad'
            />
            <TouchableOpacity onPress={increaseQuantity} style={styles.increaseButton}>
              <Ionicons name='add-circle-outline' size={25} color={colors.borderGray}/>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  )
}

export const TotalsView = ({cart, nextAction, nextActionText, store, bonusLevel}) => {

  const countTotalPrice = () => {
    var price = 0
    for (const [index, value] of cart.entries()) {
      price += value.salePrices[store] * value.quantity
    }
    return price.toFixed()
  }

  return (
    <View style={styles.totalsView}>
      <View style={styles.totalPriceView}>
        <View>  
          <Text hint={true}>К оплате</Text>
          <Text category='h1'>{countTotalPrice() + ' тг'}</Text>
        </View>
        <View>  
          <Text hint={true}>Бонусы</Text>
          <Text category='h1' style={styles.bonusText}>{'+' + (countTotalPrice() * (bonusLevel/100)).toFixed() + ' тг'}</Text>
        </View>
        <View>
          <Text hint={true}>Доставка</Text>
          <Text category='h1' style={styles.deliveryText}>~1 час</Text>
        </View>
      </View>
      <Button 
        category='primary' 
        iconName='arrow-forward-circle-outline' 
        style={styles.primaryButton}
        onPress={nextAction}
      >{nextActionText}</Button>
    </View>
  )
}
  


const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
  },
  container: {
    flex: 1,
    height: Dimensions.get('screen').height-200,
  },
  marginVertical: {
    marginVertical: 10,
  },
  cartContainer: {
    padding: 20,
  },
  cartItemCard: {
    backgroundColor: 'white',
    borderRadius: 4,
    // padding: 10,
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center',
  },
  imagePlaceholder: {
    flex: 3,
    height: 100,
    backgroundColor: colors.imageBackground
  },
  cartItemRightPart: {
    flex: 7,
    // justifyContent: 'space-between',
    paddingHorizontal: 10,
    paddingTop: 10,
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
  },
  titleView: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    // paddingHorizontal: 5,
  },
  lowerView: {
    flex: 1,
    marginTop: 'auto',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    alignItems: 'center',
  },
  priceIndividual: {
    // flex: 1,
  },
  quantityView: {
    // flex: 1,
    marginLeft: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    width: 70,
    height: 40,
    marginHorizontal: 5,
  },
  emptyCartView: {
    flex: 1,
    // height: Dimensions.get('screen').height-200,
    padding: 20,
    justifyContent: 'center',
  },
  emptyCartText: {
    // marginTop: (Dimensions.get('screen').height-300) / 2,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 50,
  },
  emptyCartImage: {
    alignSelf: 'center',
    height: 150,
    width: 365/2,
  },
  primaryButton: {
    marginTop: 'auto'
  },
  totalsView: {
    marginTop: 'auto',
    backgroundColor: 'white',
    padding: 20,
  },
  bonusText: {
    color: colors.success,
  },
  deliveryText: {
    color: colors.borderGray,
  },
  totalPriceView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  }
})