
export default {
  apiKey: "AIzaSyB6d_bqMkEO3ABiLh8y2uGHwG_vxnQRT1o",
  authDomain: "qarasay.kz",
  databaseURL: "https://qarasay-kz.firebaseio.com",
  projectId: "qarasay-kz",
  storageBucket: "qarasay-kz.appspot.com",
  messagingSenderId: "572798335623",
  appId: "1:572798335623:web:5a4d2acb155c7cb27fd622",
  measurementId: "G-JDHL7YQ667"
};
