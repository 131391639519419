import React from 'react'
import { View, StyleSheet, Dimensions } from 'react-native'
import ProductCard from './ProductCard'


export const HORIZONTAL_PADDING = 20
const BASE_PRODUCT_CARD_WIDTH = 150
const PRODUCT_CARD_MARGIN = 10
const SCREEN_WIDTH = Dimensions.get('window').width > 1200 ? 1200 : Dimensions.get('window').width

const calculateProductCardWidth = () => {
  const numberOfFullCards = Math.floor((SCREEN_WIDTH - HORIZONTAL_PADDING - PRODUCT_CARD_MARGIN) / (BASE_PRODUCT_CARD_WIDTH + PRODUCT_CARD_MARGIN))
  const extraSpace = (SCREEN_WIDTH - HORIZONTAL_PADDING - PRODUCT_CARD_MARGIN) % (BASE_PRODUCT_CARD_WIDTH + PRODUCT_CARD_MARGIN) 
  const productCardWidth = BASE_PRODUCT_CARD_WIDTH + (extraSpace / numberOfFullCards)
  return productCardWidth
}

const productCardWidth = calculateProductCardWidth()

export default function ProductGrid({ products, store }) {
  return (
    <View style={styles.horizontalProductList}>
      { products.map((item, index) => (<ProductCard key={item+index} product={item} width={productCardWidth} store={store} />)) }
    </View>
  )
}

const styles = StyleSheet.create({
  horizontalProductList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: -5
  }
})