import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import firebaseConfig from './firebaseConfig'
import AsyncStorage from '@react-native-async-storage/async-storage'

// Initialize Firebase App

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
  ignoreUndefinedProperties: true,
})

try {
  firebase.firestore().enablePersistence()
} catch (err) {
  if (err.code == 'failed-precondition') {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code == 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
  }
  console.log(err)
}

export const db = firebase.firestore().collection('organizations').doc('qarasay')
// export const db = firebase.firestore()

export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()

export const firebaseAuth = firebase.auth()

export const assignWelcomeAppBonus = firebase.functions().httpsCallable('assignWelcomeAppBonus')

export const auth = firebase.auth()

export const storage = firebase.storage().ref()

export const loginWithEmail = (email, password) =>
  auth.signInWithEmailAndPassword(email, password)

export const logout = () => auth.signOut()

export const passwordReset = email => auth.sendPasswordResetEmail(email)

export const getUserInfo = async (phone) => {
  const querySnapshot = await db.collection('clients').where('phoneNumber', '==', phone).get()
  if (querySnapshot.size > 0) {
    var client = querySnapshot.docs[0].data()
    client.id = querySnapshot.docs[0].id
    client.ref = querySnapshot.docs[0].ref
    return client
  } else {
    return null
  }
}

export const updateUserInfo = async (id, info) => {
  db.collection('clients').doc(id).update(info)
}

export const getProducts = async () => {
  const querySnapshot = await db.collection('products').where('activeInEcommerce', '==', true).get()
  var flatDocs = []
  querySnapshot.forEach(function(doc) {
    var product = doc.data()
    product.id = doc.id
    product.ref = doc.ref
    flatDocs.push(product)
  })
  return flatDocs
}

export const getProductCategories = async () => {
  const querySnapshot = await db.collection('productCategories').orderBy('order').get()
  var docs = []
  querySnapshot.forEach(function(doc) {
    var productCategory = doc.data()
    productCategory.text = productCategory.name
    productCategory.title = productCategory.name
    productCategory.id = doc.id
    productCategory.ref = doc.ref
    docs.push(productCategory)
  })
  return docs
}

export const getProductsByTag = async (tagID) => {
  const querySnapshot = await db.collection('products').where('activeInEcommerce', '==', true).where('productTags', 'array-contains', tagID).orderBy('name').get()
  var flatDocs = []
  querySnapshot.forEach(function(doc) {
    var product = doc.data()
    product.id = doc.id
    product.ref = doc.ref
    flatDocs.push(product)
  })
  return flatDocs
}

export const getLocalCart = async () => {
  const cart = await AsyncStorage.getItem('cart')
  if (cart) {
    return JSON.parse(cart)
  } else {
    return []
  }
}

export const saveOrder = async (order, userId=null) => {
  // if (userId) {
  //   await db.collection('clients').doc(userId).collection('orders').add(order)
  // } else {
  //   await db.collection('orders').add(order)
  // }
  await db.collection('orders').add(order)
}

export const getUserOrders = async (userId) => {
  const querySnapshot = await db.collection('clients').doc(userId).collection('orders').orderBy('date', 'desc').get()
  var flatDocs = []
  querySnapshot.forEach(function(doc) {
    var product = doc.data()
    product.id = doc.id
    product.ref = doc.ref
    flatDocs.push(product)
  })
  return flatDocs
}

export const getUserSales = async (clientId) => {
  const querySnapshot = await db.collection('sales').where('clientId', '==', clientId)
    // .orderBy('date', 'desc')
    .get()
  var flatDocs = []
  querySnapshot.forEach(function(doc) {
    var product = doc.data()
    product.id = doc.id
    product.ref = doc.ref
    flatDocs.push(product)
  })
  return flatDocs
}


