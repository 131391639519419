import React, { useEffect, useContext, useState }  from 'react'
import { View, ScrollView, StyleSheet, ActivityIndicator } from 'react-native'
import ProductGrid, { HORIZONTAL_PADDING } from '../../components/ProductGrid'
import Text from '../../components/Text'
import TextInput from '../../components/TextInput'
import Button from '../../components/Button'
import { UserContext } from '../../global/UserProvider'
import { getProducts } from '../../global/Firebase/firebase'
import Fuse from 'fuse.js'

export default function SearchScreen( { navigation, route, query } ) {

  const [context, setContext] = useContext(UserContext)
  const [allProducts, setAllProducts] = useState(null)
  const [filteredProducts, setFilteredProducts] = useState(null)
  // const [query, setQuery] = useState('')
  const [fuse, setFuse] = useState(null) 

  useEffect(() => {
    if (!allProducts) {
      getAllProducts()
    } else {
      handleSearch(query)
    }
  }, [query])

  const getAllProducts = async () => {
    const products = await getProducts()
    setAllProducts(products)
    const newFuse = new Fuse(products, {
      keys: ['name', 'productTags']
    })
    setFuse(newFuse)
    if (query.length > 0) {
    //   const filteredData = allProducts.filter(function(product) {
    //     return product.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
    //   })
      const filteredData = newFuse.search(query).map(i => i.item)
      setFilteredProducts(filteredData)
    }
  }

  const handleSearch = text => {
    // setQuery(text)
    const filteredData = fuse?.search(text).map(i => i.item)
    // const filteredData = allProducts.filter(function(product) {
    //   return product.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
    // })
    setFilteredProducts(filteredData)
  }
  
  return (
    <ScrollView style={styles.container}>
      {/* <TextInput 
        style={[styles.marginVertical]}
        autoCapitalize="none"
        autoCorrect={false}
        clearButtonMode="always"
        value={query}
        onChangeText={queryText => handleSearch(queryText)}
        placeholder="Поиск" 
        returnKeyType='done'
        selectTextOnFocus={true}
      /> */}
      
      { filteredProducts
        ? 
          filteredProducts.length > 0
          ?
            <ProductGrid products={filteredProducts} store={context.store} />
          :
            <Text>Не найдено</Text>
        : 
          <ActivityIndicator style={styles.marginVertical} />
      }

      <View style={{marginBottom: 50}}></View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: HORIZONTAL_PADDING,
    paddingVertical: 20,
    backgroundColor: 'white'
  },
  marginVertical: {
    marginVertical: 10,
  },
  categoriesFilter: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  categoryTag: {
    marginRight: 5,
    marginBottom: 5,
  }
})