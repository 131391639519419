import React, { useState, createContext, useEffect, useRef } from 'react'
import { auth, db, getUserInfo, getLocalCart, assignWelcomeAppBonus } from './Firebase/firebase'
import * as Analytics from 'expo-firebase-analytics'
import * as Notifications from 'expo-notifications'
import Constants from 'expo-constants'

export const UserContext = createContext({})

// const getCategory = (categoryID, categories) => {
//   for (const [index, value] of categories.entries()) {
//     if (value.id === categoryID) {
//       return value.name
//     }
//   }
// }

// const createProductsWithCategories = (products) => {
//   var docs = []
//   products.forEach(function(product) {
//     var newArr = docs[product.categoryName] ? [...docs[product.categoryName]] : []
//     newArr.push(product)
//     docs[product.categoryName] = [...newArr]
//   })
//   var docsArr = []
//   for (var key in docs) {
//     const newData = {
//       title: key,
//       data: docs[key]
//     }
//     docsArr.push(newData)
//   }
//   docsArr.sort((a, b) => parseInt(a.title) - parseInt(b.title))
//   return docsArr
// }

// const createProductsDict = (products) => {
//   var dict = {}
//   products.forEach(function(product) {
//     dict[product.id] = product
//   })
//   return dict
// }

export const UserProvider = ({ children }) => {

  const [state, setState] = useState({
    user: null,
    store: 'samal', 
    cart: [],
    // productsArray: [],
    // productsWithCategories: [],
    productsDict: {},
    productTags: [],
    slides: [],
    activeOrders: [],
    unsubscribeActiveOrders: null,
    stores: {
      samal: {
        name: 'Qarasaÿ Самал', 
        phone: '77788966474',
      }, 
      zenkova: {
        name: 'Qarasaÿ Зенкова',
        phone: '77029827007',
      }
    }
  })

  const notificationListener = useRef();
  const responseListener = useRef();

  useEffect(() => {
    // loadProducts()
    const unsubscribeAuth = auth.onAuthStateChanged(async authUser => {
      setState(state => ({...state, user: authUser}))
      if (authUser) {
        loadUserInfo(authUser)
      }
      Analytics.setUserId(authUser?.uid)
    })

    // This listener is fired whenever a notification is received while the app is foregrounded
    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
      console.log(notification)
    })

    // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
    responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
      console.log(response)
    })
    
    loadCart()
    return () => {
      unsubscribeAuth()
      state.unsubscribeActiveOrders?.()
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    }
  }, [])

  const loadUserInfo = async (authUser) => {
    const phoneNumber = authUser.phoneNumber?.replace('+', '')
    const info = await getUserInfo(phoneNumber)
    const pushToken = await registerForPushNotificationsAsync()
    if (info) {
      const updatedUserInfo = {...info, id: info.id, ref: info.ref}
      setState(state => ({...state, user: {...state.user, ...updatedUserInfo}, store: info.store}))
      info.ref.update({pushToken, uid: authUser.uid, store: 'samal'})
    } else {
      const newUser = { 
        uid: authUser.uid, 
        store: 'samal', 
        phoneNumber: phoneNumber, 
        bonusValue: 0, 
        bonusLevel: 3,
        totalPurchasedValue: 0,
        registrationSource: 'app',
        registrationDate: new Date(),
        pushToken
      }
      await db.collection('clients').doc(authUser.uid).set(newUser)
      const updatedUserInfo = {...newUser, id: authUser.uid}
      setState(state => ({...state, user: {...state.user, ...updatedUserInfo}, store: 'samal'}))
    }
    const unsubscribeActiveOrders = db.collection('orders').where('clientId', '==', (info ? info.id : authUser.uid)).where('isProcessed', '==', false).onSnapshot(function(querySnapshot) {
      var docs = []
      querySnapshot.forEach(function(doc) {
        var order = doc.data()
        order.id = doc.id
        order.ref = doc.ref
        docs.push(order)
      })
      setState(state => ({...state, activeOrders: docs, unsubscribeActiveOrders}))
    })
  }

  const loadCart = async () => {
    const cart = await getLocalCart()
    setState(state => ({...state, cart}))
  }

  async function registerForPushNotificationsAsync() {
    let token;
    if (Constants.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        alert('Failed to get push token for push notification!');
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
    } else {
      alert('Must use physical device for Push Notifications');
    }
  
    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }
  
    return token;
  }

  // const loadProducts = async () => {
  //   const products = await getProducts()
  //   const productCategories = await getProductCategories()
  //   var productsArray = []
  //   for (const [index, value] of products.entries()) {
  //     var updatedValue = {...value}
  //     updatedValue['categoryName'] = getCategory(value.categoryID, productCategories)
  //     productsArray.push(updatedValue)
  //   }
  //   const productsWithCategories = createProductsWithCategories(productsArray)
  //   const productsDict = createProductsDict(productsArray)
  //   setState(state => ({...state, productsArray, productsWithCategories, productsDict}))
  // }

  return (
    <UserContext.Provider value={[ state, setState ]}>
      {children}
    </UserContext.Provider>
  )
}  
