import React, { useEffect, useContext, useState }  from 'react'
import { View, ScrollView, StyleSheet, Image, Dimensions, Linking } from 'react-native'
import { UserContext } from '../../global/UserProvider'
import NextImage from 'next/image'
import Text from '../../components/Text'
import { logout } from '../../global/Firebase/firebase'
import { Ionicons } from '@expo/vector-icons'
import colors from '../../utils.js/colors'
import { TouchableOpacity } from 'react-native-gesture-handler'
import * as Analytics from 'expo-firebase-analytics'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

const menuItems = [
  {
    name: 'История покупок', 
    icon: 'time-outline',
    onPress: (navigation) => {
      navigation.navigate('Orders')
    }
  },
  // {
  //   name: 'Мои адреса', 
  //   icon: 'pin',
  //   onPress: () => {}
  // },
  {
    name: 'О Qarasay', 
    icon: 'information-circle-outline',
    onPress: (navigation) => {
      navigation.navigate('Welcome')
    }
  },
  {
    name: 'Написать в Qarasaÿ Самал', 
    icon: 'logo-whatsapp',
    onPress: () => {
      Analytics.logEvent('WriteToWhatsapp', {
        store: 'samal'
      })
      Linking.openURL('https://wa.me/77029827007')
    }
  },
  {
    name: 'Посмотреть наш Instagram', 
    icon: 'logo-instagram',
    onPress: () => {
      Analytics.logEvent('OpenInstagram')
      Linking.openURL('https://instagram.com/qarasay_kz')
    }
  },
  {
    name: 'Выйти', 
    icon: 'log-out-outline',
    onPress: () => {
      Analytics.logEvent('LogOut')
      logout()
    }
  },
]

export default function ProfileScreen( { navigation, route } ) {

  const [context, setContext] = useContext(UserContext)
  
  return (
    <ScrollView style={styles.container}>
      <TouchableOpacity style={styles.profileRow} onPress={() => {
        navigation.navigate('UpdateProfile')
      }}>
        {context.user?.photoURL ? 
          <>
            {/* { Platform.OS === 'web' && 
              (<NextImage src={context.user?.photoURL} layout='fill' />) 
            }
            { Platform.OS !== 'web' && 
              (<Image source={{ uri: context.user?.photoURL }} style={styles.image} />)
            } */}
            <Image source={{ uri: context.user?.photoURL }} style={styles.image} />
          </>
          : (
            <Ionicons name='person-circle' size={70} color={colors.gray} />
          )
        }
        <View style={{marginLeft: 5, flexDirection: 'row', alignItems: 'center'}}>
          <View>
            <Text category='s2'>{context.user?.displayName}</Text>
            <Text category='s2'>{context.user?.phoneNumber}</Text>
          </View>
        </View>
        <Ionicons name='chevron-forward' size={18} color={colors.borderGray} style={{marginLeft: 'auto'}} />
      </TouchableOpacity>

      <View style={{
        marginVertical: 20,
        marginHorizontal: 20,
        borderRadius: 10,
        backgroundColor: 'white',
        padding: 20
      }}>
        <Text style={{textAlign: 'center', marginBottom: 20}}>Программа лояльности</Text>
        <Image source={{ uri: 'https://bwipjs-api.metafloor.com/?bcid=ean13&text=2' + context.user?.phoneNumber + '&includetext&height=10' }} style={{resizeMode: 'contain', height: 50 }} />
        <View style={{position: 'absolute', marginLeft: 16, marginTop: 16, flexDirection: 'row', alignItems: 'center', borderRadius: 5, padding: 2, backgroundColor: colors.success}}>
          <Ionicons name='gift-outline' size={16} color='white' />
          <Text center style={{color: 'white', fontSize: 16}}>{context.user?.bonusLevel + '%'}</Text>
        </View>
        <View>
          <Text center category='h1' style={{marginTop: 10, color: colors.success}}>{context.user?.bonusValue + 'тг'}</Text>
          <Text center hint={true}>бонусов</Text>
        </View>
      </View>

      { context.activeOrders.length > 0 && (
        <View style={{
          marginBottom: 20,
          marginHorizontal: 20,
          borderRadius: 10,
          backgroundColor: 'white',
          padding: 20
        }}>
          <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 10}}>
            <Text style={{textAlign: 'center'}}>Действующие заказы</Text>
            <View style={{marginLeft: 5, alignItems: 'center', justifyContent: 'center', borderRadius: 8, height: 16, width: 16, backgroundColor: colors.danger}}>
              <Text center style={{color: 'white', fontSize: 10}}>{context.activeOrders.length}</Text>
            </View>
          </View>
          
          { context.activeOrders.map((item, id) => (
            <View>
              <Text><Ionicons name='calendar-outline' size={18} color='black' />{' ' + format(item.date.toDate(), "d/M/y H:m", {locale: ru}) }</Text>
              { item.deliveryMethod == 1 && <Text><Ionicons name='ios-map-outline' size={18} color='black' />{' Самовывоз с ' + context.stores[item.store].name }</Text> }
              { item.deliveryMethod == 0 && <Text><Ionicons name='ios-map-outline' size={18} color='black' />{' ' + item.address}</Text> }
              <Text><Ionicons name='call-outline' size={18} color='black' />{' ' + item.phone}</Text>
              <Text style={{marginTop:10}}>Товары:</Text>
              { item.cart.map((cartItem, cartItemIndex) => (
                <View key={cartItem+cartItemIndex} style={{flexDirection: 'row', alignItems: 'center'}}> 
                  <Text style={{flex: 5}}>{cartItem.name}</Text>
                  <Text style={{flex: 1}}>{cartItem.quantity.toFixed(1)}</Text>
                  <Text style={{flex: 1}}>{(cartItem.salePrices[item.store] * cartItem.quantity).toFixed()}</Text>
                </View>
              )) }
            </View>
          ))}
          <Text hint style={{textAlign: 'center', marginTop: 10}}>В скором времени с вами свяжется наш оператор. Спасибо за заказ!</Text>
        </View>
      )}

      <View style={styles.menu}>
        { menuItems.map((item, index) => (
          <TouchableOpacity key={item+index} style={styles.menuItem} onPress={() => item.onPress(navigation)}>
            <Ionicons name={item.icon} size={18} color='black' style={{marginRight: 6}} />
            <Text category='s1'>{item.name}</Text>
            <Ionicons name='chevron-forward' size={18} color={colors.borderGray} style={{marginLeft: 'auto'}}/>
          </TouchableOpacity>
        ))}
      </View>
      <View style={{marginBottom: 0}}></View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    // paddingTop: 20,
    flex: 1,
  },
  profileRow: {
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'space-between',
    backgroundColor: 'white',
    paddingHorizontal: 20,
  },
  bonusText: {
    color: 'white',
    fontSize: 16
  },
  menu: {
    backgroundColor: 'white', 
    paddingHorizontal: 20,
  },
  menuItem: {
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'space-between',
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderColor: colors.lightGray
  },
})