import React, { useEffect, useContext, useState }  from 'react'
import { Platform, View, StyleSheet, TouchableOpacity, Dimensions, Image } from 'react-native'
import { addItemToCart, storage } from '../global/Firebase/firebase'
import colors from '../utils.js/colors'
import connectToContext from '../utils.js/connectToContext'
import Text from './Text'
import NextImage from './NextImage'
import { Ionicons } from '@expo/vector-icons'
import { UserContext } from '../global/UserProvider'
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as Analytics from 'expo-firebase-analytics'
// import CachedImage from 'react-native-expo-cached-image'


const ProductCard = React.memo(({ product, width, cartIndex, quantity, setContext, store }) => {

  return (
    <View style={[styles.container, {flexBasis: width, marginHorizontal: 5}]}>
      <View style={styles.imagePlaceholder}>
        {/* { Platform.OS === 'web' && product.productImages && product.productImages[0] && 
          <NextImage src={'https://storage.googleapis.com/qarasay-kz.appspot.com/productImages/' + encodeURIComponent(product.productImages[0].fileName) } layout='fill' />
        } */}
        { product.productImages && product.productImages[0] && 
          <Image source={{ uri: 'https://storage.googleapis.com/qarasay-kz.appspot.com/productImages/' + encodeURIComponent(product.productImages[0].fileName) }} style={styles.image} />
          // <CachedImage source={{ uri: 'https://storage.googleapis.com/qarasay-kz.appspot.com/productImages/' + encodeURIComponent(product.productImages[0].fileName) }} style={styles.image} />
        }
      </View>
      <Text style={styles.name}>
        {product.name}
      </Text>
      <View style={styles.row}>
        <View style={styles.pricesView}>
          <Text category='s1' style={styles.price}>
            {product.salePrices[store] + ' тг'}
          </Text>
          { product.oldPrice != null && (
            <Text style={styles.oldPrice}>
              {product.oldPrice + ' тг'}
            </Text>
          )}
        </View>
        <TouchableOpacity style={{
          backgroundColor: cartIndex == -1 ? colors.primary : 'white',
          borderColor: cartIndex == -1 ? 'white' : colors.primary,
          borderWidth: 1,
          borderRadius: 5,
          width: 60,
          height: 25,
          marginHorizontal: 10,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row'
        }} onPress={() => {
          setContext(context => {
            var newCart = [...context.cart]
            if (cartIndex === -1) {
              newCart.push({
                id: product.id,
                name: product.name,
                salePrices: product.salePrices,
                activeInStore: product.activeInStore,
                storeStock: product.storeStock,
                productImages: product.productImages,
                defaultQuantity: product.defaultQuantity,
                quantity: (product.defaultQuantity || 1),
                type: product.type,
              })
            } else {
              const cartItem = newCart[cartIndex]
              const updatedCartItem = {...cartItem, quantity: quantity + 1}
              newCart.splice(cartIndex, 1, updatedCartItem)
            }
            AsyncStorage.setItem('cart', JSON.stringify(newCart))
            Analytics.logEvent('AddProductToCart', {
              product: product.name,
              store: store,
            })
            return {...context, cart: newCart}
          })
        }}>
          <Ionicons name={cartIndex == -1 ? 'add-outline' : 'checkmark'} size={10} color={cartIndex == -1 ? 'white' : colors.primary} style={{marginRight: -3}} />
          <Ionicons name='cart-outline' size={20} color={cartIndex == -1 ? 'white' : colors.primary} style={styles.cartIcon} />
        </TouchableOpacity>
      </View>
    </View>
  )
}, function(prev, next) {
  if (prev.cartIndex != next.cartIndex) {
    return false
  }
  if (prev.quantity != next.quantity) {
    return false
  }
  if (prev.key !== next.key) {
    return false
  }
  if (prev.product.id !== next.product.id) {
    return false
  }
  return true
})

function select({ product }) {
  const [context, setContext] = useContext(UserContext)
  const cartIndex = context.cart.findIndex(item => (
    item.id.indexOf(product.id) > -1
  ))

  return {
    product,
    cart: context.cart,
    cartIndex,
    quantity: cartIndex != -1 ? context.cart[cartIndex].quantity : 0,
    context: context,
    setContext: setContext,
  }
}

const styles = StyleSheet.create({
  container: {
    height: 270,
    borderRadius: 4,
    // boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1)',
    paddingBottom: 0,
    marginBottom: 10,
    backgroundColor: 'white',
    overflow: 'hidden',
  }, 
  imagePlaceholder: {
    flex: 10,
    // width: 150,
    backgroundColor: colors.imageBackground
  }, 
  image: {
    flex: 1,
    resizeMode: 'cover',
  },
  name: {
    marginTop: 5,
    marginHorizontal: 10,
    // height: 30,
    flex: 3,
    overflow: 'hidden',
  },
  pricesView: {
    marginHorizontal: 10,
  },
  price: {
    overflow: 'hidden',
  },
  oldPrice: {
    textDecorationLine: 'line-through',
    overflow: 'hidden',
    color: 'gray'
  },
  row: {
    flex: 3,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

export default connectToContext(ProductCard, select)