import React, { useEffect, useContext, useState, useRef }  from 'react'
import HomeStack from './HomeStack'
import ProfileStack from './ProfileStack'
import CartStack from './CartStack'
import CatalogStack from './CatalogStack'
import { Ionicons } from '@expo/vector-icons'
import colors from '../../utils.js/colors'
import { UserContext } from '../UserProvider'
import { NavigationContainer } from '@react-navigation/native'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import * as Analytics from 'expo-firebase-analytics'
import * as Linking from 'expo-linking'
import { Platform } from 'react-native'

const prefix = Linking.createURL('/')

const Tab = createBottomTabNavigator()

export default function TabNavigator() {
  const [context, setContext] = useContext(UserContext)
  const navigationRef = useRef();
  const routeNameRef = useRef();

  function getActiveRouteName(navigationState) {
    if (!navigationState) return null;
    const route = navigationState.routes[navigationState.index];
    // Parse the nested navigators
    if (route.routes) return getActiveRouteName(route);
    return route.routeName;
  }

  const linking = {
    prefixes: [prefix, 'qarasay://', 'https://qarasay.kz', 'https://qarasay.netlify.app', 'http://qarasay-kz.web.app'],
    config: {
      screens: {
        HomeStack: {
          initialRouteName: 'Home',
          screens: {
            Home: '',
            ProductTag: 'category/:productTag',
          }
        },
        CartStack: {
          initialRouteName: 'Cart',
          screens: {
            Cart: 'cart',
          },
        },
        ProfileStack: {
          initialRouteName: 'Profile',
          screens: {
            Profile: 'profile',
            Welcome: 'welcome',
            Login: 'login',
            Login2: 'verify',
          },
        },
      },
    }
  }
  
  return (
    <NavigationContainer
      ref={navigationRef}
      linking={linking}
      onReady={() =>
        (routeNameRef.current = navigationRef.current.getCurrentRoute().name)
      }
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.current.getCurrentRoute().name;

        if (previousRouteName !== currentRouteName) {
          // The line below uses the expo-firebase-analytics tracker
          // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
          // Change this line to use another Mobile analytics SDK
          Analytics.setCurrentScreen(currentRouteName)
        }

        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName;
      }}
    >
      <Tab.Navigator
        screenOptions={({ route }) => ({
          tabBarIcon: ({ focused, color, size }) => {
            let iconName
            let updatedSize = size

            if (route.name === 'HomeStack') {
              iconName = focused
                ? 'home'
                : 'home-outline'
              updatedSize -= 2
            } else if (route.name === 'CatalogStack') {
              iconName = focused 
                ? 'list' 
                : 'list-outline'
              updatedSize += 4
            } else if (route.name === 'CartStack') {
              iconName = focused 
                ? 'cart' 
                : 'cart-outline'
            } else if (route.name === 'ProfileStack') {
              iconName = focused 
                ? 'person-circle' 
                : 'person-circle-outline'
            }

            // You can return any component that you like here!
            return <Ionicons name={iconName} size={updatedSize} color={color} />
          },
          "tabBarActiveTintColor": colors.primary,
          "tabBarInactiveTintColor": "gray",
          "tabBarStyle": [
            {
              "display": "flex"
            },
            null
          ],
          headerShown: false
        })}
      >
        <Tab.Screen name='HomeStack' component={HomeStack} options={{ title: 'Главная' }} />
        {/* <Tab.Screen name='CatalogStack' component={CatalogStack} options={{ title: 'Каталог' }} /> */}
        <Tab.Screen name='CartStack' component={CartStack} options={{ title: 'Корзина', tabBarBadge: context.cart?.length > 0 ? context.cart?.length : null }} />
        { Platform.OS != 'web' && <Tab.Screen name='ProfileStack' component={ProfileStack} options={{ title: 'Профиль', tabBarBadge: context.activeOrders?.length > 0 ? context.activeOrders?.length : null }} /> }
      </Tab.Navigator>
    </NavigationContainer>
  )
}